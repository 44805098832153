<template>
  <div>
    <NordicWalkingComp v-bind:mobile="mobile"/>
  </div>
</template>

<script>
import NordicWalkingComp from "@/components/desktop/Activities/NordicWalkingComp";

  export default {
    name: 'NordicWalking',
    props: ['mobile'],
    components: {
      NordicWalkingComp,
    },
  }
</script>
