<template>
  <div>
    <v-row justify="center" no-gutters>
      <h1 v-bind:class="mobile ? 'mainTitleMobile' : 'mainTitle'">{{ $t('mainTitle') }}</h1>
    </v-row>
    <v-icon
        v-bind:class="mobile ? 'iconBackMobile' : 'iconBack'"
        v-bind:size="mobile ? 40 : 50"
        color="#1579A7"
        @click="$router.push({name: 'PhysicalActivities'}).then(() => {$vuetify.goTo(0)})"
    >
      mdi-arrow-left
    </v-icon>
    <v-row justify="center" align="center" v-bind:class="mobile ? 'sectionMobile' : 'section'" no-gutters>
      <v-col v-bind:cols="mobile ? 10 : 6" class="leftSection">
        <p v-bind:class="mobile ? 'textSection1Mobile' : 'textSection1'" v-html="$t('textSection1')"></p>
        <p v-bind:class="mobile ? 'textSection1Mobile' : 'textSection1'" style="color: #57939E;" v-html="$t('textSection2')"></p>
        <p v-bind:class="mobile ? 'textSection1Mobile' : 'textSection1'" v-html="$t('textSection3')"></p>
      </v-col>
      <v-col v-bind:cols="mobile ? 10 : 6" v-bind:class="mobile ? 'rightSectionMobile' : 'rightSection'">
        <v-img
            :src="require('@/assets/conferenceFormation/conference11.jpg')"
            contain
            v-bind:height="mobile ? 250 : 375"
        />
      </v-col>
    </v-row>
    <v-row justify="center" class="secondSectionMobile" no-gutters v-if="mobile">
      <v-col cols="6">
        <v-img
            :src="require('@/assets/activities/heart.svg')"
            contain
            height="50"
        />
        <div class="textPart" v-html="$t('textPart1')"></div>
      </v-col>
      <v-col cols="6" align-self="center">
        <v-img
            :src="require('@/assets/activities/2.svg')"
            contain
            height="20"
        />
        <v-img
            :src="require('@/assets/activities/4.svg')"
            contain
            height="20"
        />
      </v-col>
      <v-col cols="6">
        <v-img
            :src="require('@/assets/activities/alter.svg')"
            contain
            height="50"
        />
        <div class="textPart" v-html="$t('textPart2')"></div>
      </v-col>
      <v-col cols="6" align-self="center">
        <v-img
            :src="require('@/assets/activities/3.svg')"
            contain
            height="20"
        />
      </v-col>
      <v-col cols="6">
        <v-img
            :src="require('@/assets/activities/run.svg')"
            contain
            height="50"
        />
        <div class="textPart" v-html="$t('textPart3')"></div>
      </v-col>
      <v-col cols="6" align-self="center">
        <v-img
            :src="require('@/assets/activities/5.svg')"
            contain
            height="20"
        />
      </v-col>
      <v-col cols="6">
        <v-img
            :src="require('@/assets/activities/sport.svg')"
            contain
            height="50"
        />
        <div class="textPart" v-html="$t('textPart4')"></div>
      </v-col>
      <v-col cols="6" align-self="center">
        <v-img
            :src="require('@/assets/activities/2.svg')"
            contain
            height="20"
        />
      </v-col>
      <v-col cols="6">
        <v-img
            :src="require('@/assets/activities/graph.svg')"
            contain
            height="50"
        />
        <div class="textPart" v-html="$t('textPart5')"></div>
      </v-col>
      <v-col cols="6" align-self="center">
        <v-img
            :src="require('@/assets/activities/1.svg')"
            contain
            height="20"
        />
      </v-col>
    </v-row>
    <v-row justify="space-between" class="secondSection" no-gutters v-if="!mobile">
      <v-col cols="2">
        <v-img
            :src="require('@/assets/activities/heart.svg')"
            contain
            height="75"
        />
        <div class="textPart" v-html="$t('textPart1')"></div>
        <v-img
            :src="require('@/assets/activities/2.svg')"
            contain
            height="25"
        />
        <v-img
            style="margin-top: 2px;"
            :src="require('@/assets/activities/4.svg')"
            contain
            height="25"
        />
      </v-col>
      <v-col cols="2">
        <v-img
            :src="require('@/assets/activities/alter.svg')"
            contain
            height="75"
        />
        <div class="textPart" v-html="$t('textPart2')"></div>
        <v-img
            :src="require('@/assets/activities/3.svg')"
            contain
            height="25"
        />
      </v-col>
      <v-col cols="2">
        <v-img
            :src="require('@/assets/activities/run.svg')"
            contain
            height="75"
        />
        <div class="textPart" v-html="$t('textPart3')"></div>
        <v-img
            :src="require('@/assets/activities/5.svg')"
            contain
            height="25"
        />
      </v-col>
      <v-col cols="2">
        <v-img
            :src="require('@/assets/activities/sport.svg')"
            contain
            height="75"
        />
        <div class="textPart" v-html="$t('textPart4')"></div>
        <v-img
            :src="require('@/assets/activities/2.svg')"
            contain
            height="25"
        />
      </v-col>
      <v-col cols="2">
        <v-img
            :src="require('@/assets/activities/graph.svg')"
            contain
            height="75"
        />
        <div v-bind:class="mobile ? 'textPartMobile' : 'textPart'" v-html="$t('textPart5')"></div>
        <v-img
            :src="require('@/assets/activities/1.svg')"
            contain
            height="25"
        />
      </v-col>
      <v-col cols="12" class="chevronDown" v-if="!mobile">
        <v-icon
            class="iconEndSection"
            size="75"
            color="#1579A7"
        >
          mdi-chevron-down
        </v-icon>
      </v-col>
    </v-row>
    <v-row justify="center" no-gutters>
      <div v-bind:class="mobile ? 'mainTitleMobile' : 'secondTitle'">{{ $t('secondTitle') }}</div>
    </v-row>
    <div class="lastSectionMobile" v-if="mobile">
      <v-card
          class="cardMobile"
          max-width="250"
      >
        <v-card-title>
          <div v-bind:class="mobile ? 'cardTitleMobile' : 'cardTitle'">{{ $t('cardTitle1') }}</div>
        </v-card-title>
        <v-card-subtitle>
          <v-img
              class="separatorCard"
              :src="require('@/assets/picto/separator14.svg')"
              contain
              v-bind:height="mobile ? 20 : 25"
          />
        </v-card-subtitle>
        <v-card-text>
          <div v-bind:class="mobile ? 'cardTextMobile' : 'cardText'">{{ $t('cardText1') }}</div>
          <v-row justify="center" align="center" no-gutters class="locCard">
            <v-col cols="2">
              <v-img
                  :src="require('@/assets/activities/loc.svg')"
                  contain
                  v-bind:height="mobile ? 30 : 40"
              />
            </v-col>
            <v-col cols="10">
              <div>{{ $t('cardText2') }}</div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card
          class="cardMobile"
          max-width="250"
      >
        <v-card-title>
          <div v-bind:class="mobile ? 'cardTitleMobile' : 'cardTitle'">{{ $t('cardTitle2') }}</div>
        </v-card-title>
        <v-card-subtitle>
          <v-img
              class="separatorCard"
              :src="require('@/assets/picto/separator14.svg')"
              contain
              v-bind:height="mobile ? 20 : 25"
          />
        </v-card-subtitle>
        <v-card-text>
          <div v-bind:class="mobile ? 'cardTextMobile' : 'cardText'">{{ $t('cardText3') }}</div>
          <v-row justify="center" align="center" no-gutters class="locCard">
            <v-col cols="2">
              <v-img
                  :src="require('@/assets/activities/loc.svg')"
                  contain
                  v-bind:height="mobile ? 30 : 40"
              />
            </v-col>
            <v-col cols="10">
              <div>{{ $t('cardText4') }}</div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card
          class="cardMobile"
          max-width="250"
          style="margin-right: 50px;"
      >
        <v-card-title>
          <div v-bind:class="mobile ? 'cardTitleMobile' : 'cardTitle'">{{ $t('cardTitle3') }}</div>
        </v-card-title>
        <v-card-subtitle>
          <v-img
              class="separatorCard"
              :src="require('@/assets/picto/separator14.svg')"
              contain
              v-bind:height="mobile ? 20 : 25"
          />
        </v-card-subtitle>
        <v-card-text>
          <div v-bind:class="mobile ? 'cardTextMobile' : 'cardText'">{{ $t('cardText5') }}</div>
          <v-row justify="center" align="center" no-gutters class="locCard">
            <v-col cols="2">
              <v-img
                  :src="require('@/assets/activities/loc.svg')"
                  contain
                  v-bind:height="mobile ? 30 : 40"
              />
            </v-col>
            <v-col cols="10">
              <div>{{ $t('cardText6') }}</div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
    <v-row justify="center" align="center" class="lastSection" no-gutters v-if="!mobile">
      <v-col cols="3">
        <v-card
            class="mx-auto"
            max-width="344"
            style="text-align: center;"
        >
          <v-card-title>
            <div v-bind:class="mobile ? 'cardTitleMobile' : 'cardTitle'">{{ $t('cardTitle1') }}</div>
          </v-card-title>
          <v-card-subtitle>
            <v-img
                class="separatorCard"
                :src="require('@/assets/picto/separator14.svg')"
                contain
                v-bind:height="mobile ? 20 : 25"
            />
          </v-card-subtitle>
          <v-card-text>
            <div v-bind:class="mobile ? 'cardTextMobile' : 'cardText'">{{ $t('cardText1') }}</div>
            <v-row justify="center" align="center" no-gutters class="locCard">
              <v-col cols="2">
                <v-img
                    :src="require('@/assets/activities/loc.svg')"
                    contain
                    v-bind:height="mobile ? 20 : 40"
                />
              </v-col>
              <v-col cols="10">
                <div>{{ $t('cardText2') }}</div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card
            class="mx-auto"
            max-width="344"
            style="text-align: center;"
        >
          <v-card-title>
            <div v-bind:class="mobile ? 'cardTitleMobile' : 'cardTitle'">{{ $t('cardTitle2') }}</div>
          </v-card-title>
          <v-card-subtitle>
            <v-img
                class="separatorCard"
                :src="require('@/assets/picto/separator14.svg')"
                contain
                v-bind:height="mobile ? 20 : 25"
            />
          </v-card-subtitle>
          <v-card-text>
            <div v-bind:class="mobile ? 'cardTextMobile' : 'cardText'">{{ $t('cardText3') }}</div>
            <v-row justify="center" align="center" no-gutters class="locCard">
              <v-col cols="2">
                <v-img
                    :src="require('@/assets/activities/loc.svg')"
                    contain
                    v-bind:height="mobile ? 20 : 40"
                />
              </v-col>
              <v-col cols="10">
                <div>{{ $t('cardText4') }}</div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card
            class="mx-auto"
            max-width="344"
            style="text-align: center;"
        >
          <v-card-title>
            <div v-bind:class="mobile ? 'cardTitleMobile' : 'cardTitle'">{{ $t('cardTitle3') }}</div>
          </v-card-title>
          <v-card-subtitle>
            <v-img
                class="separatorCard"
                :src="require('@/assets/picto/separator14.svg')"
                contain
                v-bind:height="mobile ? 20 : 25"
            />
          </v-card-subtitle>
          <v-card-text>
            <div v-bind:class="mobile ? 'cardTextMobile' : 'cardText'">{{ $t('cardText5') }}</div>
            <v-row justify="center" align="center" no-gutters class="locCard">
              <v-col cols="2">
                <v-img
                    :src="require('@/assets/activities/loc.svg')"
                    contain
                    v-bind:height="mobile ? 20 : 40"
                />
              </v-col>
              <v-col cols="10">
                <div>{{ $t('cardText6') }}</div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-img
        v-if="!mobile"
        class="separatorLast"
        :src="require('@/assets/picto/separator15.svg')"
        contain
        height="32"
    />
    <v-img
        v-if="mobile"
        class="separatorLastMobile"
        :src="require('@/assets/picto/separator16.svg')"
        contain
        height="22"
    />
  </div>
</template>

<script>
  export default {
    name: 'NordicWalkingComp',
    props: ['mobile'],
    data: () => ({
    }),
  }
</script>

<i18n>
{
  "en": {
    "mainTitle": "Nordic walking",
    "secondTitle": "Where to practice?",
    "cardTitle1": "Auribeau sur siagnes",
    "cardText1": "In construction",
    "cardText2": "Be there soon",
    "cardTitle2": "Chateauneuf de Grasse /opio /rouret",
    "cardText3": "In construction",
    "cardText4": "Be there soon",
    "cardTitle3": "Auribeau sur siagnes",
    "cardText5": "In construction",
    "cardText6": "Be there soon",
    "textPart1": "Intensity",
    "textPart2": "Muscular work",
    "textPart3": "Heart work",
    "textPart4": "Balance work",
    "textPart5": "Required level",
    "textSection1": "Nordic walking is a dynamic walking practice surrounded by nature. It ideally combines endurance work accessible to all, walking, and muscle strengthening of the whole body.",
    "textSection2": "The principle is simple: it involves accentuating the natural movement of the arms during walking and propelling the body forward with the help of two sticks which allow you to go faster and walk longer.",
    "textSection3": "The whole body comes into action, thus strengthening 80% of the muscle chains. The energy expenditure is equivalent to a short jog. This activity provides pleasure and well-being due to the simplicity of its technique and is therefore aimed at all audiences."
  },
  "fr": {
    "mainTitle": "La marche nordique",
    "secondTitle": "Où pratiquer ?",
    "cardTitle1": "Auribeau sur siagnes",
    "cardText1": "En construction",
    "cardText2": "Arrive bientôt",
    "cardTitle2": "Chateauneuf de Grasse /opio /rouret",
    "cardText3": "En construction",
    "cardText4": "Arrive bientôt",
    "cardTitle3": "Auribeau sur siagnes",
    "cardText5": "En construction",
    "cardText6": "Arrive bientôt",
    "textPart1": "Intensité",
    "textPart2": "Travail musculaire",
    "textPart3": "Travail cardio",
    "textPart4": "Travail d'équilibre",
    "textPart5": "Niveau requis",
    "textSection1": "La marche nordique est une pratique de marche dynamique en pleine nature. Elle allie de manière idéale un travail d’endurance accessible à tous, la marche, et un renforcement musculaire de l’ensemble du corps.",
    "textSection2": " Le principe est simple : il s’agit d’accentuer le mouvement naturel des bras pendant la marche et de propulser le corps vers l’avant à l’aide de deux bâtons qui permettent d’aller plus vite et de marcher plus longtemps.",
    "textSection3": "L’ensemble du corps entre en action, renforçant ainsi 80% des chaînes musculaires. La dépense énergétique est équivalente à un petit footing. Cette activité procure plaisir et bien être du fait de la simplicité de sa technique et s’adresse ainsi à tous les publics."
  }
}
</i18n>

<style scoped>
.leftSection {
  padding-left: 200px;
  text-align: left;
}
@media screen and (max-width: 1500px) {
  .section {
    padding-left: 200px;
    padding-right: 200px;
  }
  .secondSection {
    padding-left: 200px;
    padding-right: 200px;
    position: relative;
    color: rgba(50, 50, 50, 0.75);
    box-shadow: 0 7px 3px -3px rgba(87, 147, 158, 0.20);
  }
  .chevronDown {
    text-align: center;
  }
  .lastSection {
  }
}
@media screen and (min-width: 1500px) {
  .section {
    padding-left: 200px;
    padding-right: 200px;
    padding-bottom: 50px;

  }
  .secondSection {
    padding-left: 200px;
    padding-right: 200px;
    position: relative;
    color: rgba(50, 50, 50, 0.75);
    box-shadow: 0 7px 3px -3px rgba(87, 147, 158, 0.20);
  }
  .chevronDown {
    text-align: center;
  }
  .lastSection {
    padding-left: 200px;
    padding-right: 200px;
  }
}

.cardMobile {
  margin-left: 50px;
  text-align: center;
  flex: 0 0 auto;
}

.lastSectionMobile {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding-bottom: 60px;
}

.sectionMobile {
  position: relative;
  color: rgba(50, 50, 50, 0.75);
  box-shadow: 0 7px 3px -3px rgba(87, 147, 158, 0.20);
}

.secondSectionMobile {
  padding-top: 50px;
  padding-bottom: 40px;
  position: relative;
  color: rgba(50, 50, 50, 0.75);
  box-shadow: 0 7px 3px -3px rgba(87, 147, 158, 0.20);
}

.locCard {
  text-align: left;
  padding-top: 20px;
}

.cardText {
  font-weight: 600;
}

.separatorCard {
  margin-top: 20px;
  margin-bottom: 20px;
}

.separatorLast {
  margin-top: 40px;
  margin-bottom: 80px;
}

.separatorLastMobile {
  margin-top: 20px;
  margin-bottom: 40px;
}

.cardTitle {
  font-size: 22px;
  font-weight: 800;
  font-family: Comfortaa, sans-serif;
  color: #1579A7;
}

.cardTitleMobile {
  font-size: 16px;
  font-weight: 800;
  font-family: Comfortaa, sans-serif;
  color: #1579A7;
}

.textPart {
  padding-top: 15px;
  padding-bottom: 15px;
  font-weight: bold;
  font-family: Comfortaa, sans-serif;
  text-align: center;
  font-size: 18px;
  color: #1579A7;
}

.textPartMobile {

}

.secondTitle {
  padding-top: 60px;
  padding-bottom: 60px;
  font-size: 30px;
  font-weight: 800;
  font-family: Comfortaa, sans-serif;
  color: #1579A7;
}

.iconBack {
  position: absolute;
  top: 30px;
  left: 80px;
}


.iconBackMobile {
  position: absolute;
  top: 55px;
  left: 30px;
}

.rightSection {
  padding-right: 200px;
}

.rightSectionMobile {
  margin-bottom: 40px;
}

.textSection1 {
  padding-right: 100px;
  color: #808080;
}
.textSection1Mobile {
  font-size: 14px ;
  color: #808080;
}
</style>
